import React from "react";

const getInputNumbersValue = function (input) {
  return input.value.replace(/\D/g, "");
};

const getFormattedPhoneInputValue = function (numbers) {
  let inputNumbersValue = numbers;
  let formattedInputValue = "";
  if (["7", "8", "9"].indexOf(inputNumbersValue[0]) > -1) {
    // russian number
    if (inputNumbersValue[0] === "9")
      inputNumbersValue = "7" + inputNumbersValue;

    let firstSymbols = inputNumbersValue[0] === "8" ? "+7" : "+7";

    formattedInputValue = firstSymbols + " ";

    if (inputNumbersValue.length > 1)
      formattedInputValue += +inputNumbersValue.substring(1, 4);

    if (inputNumbersValue.length >= 5)
      formattedInputValue += " " + inputNumbersValue.substring(4, 7);

    if (inputNumbersValue.length >= 8)
      formattedInputValue += " " + inputNumbersValue.substring(7, 9);

    if (inputNumbersValue.length >= 10)
      formattedInputValue += " " + inputNumbersValue.substring(9, 11);

    return formattedInputValue;
  } else {
    //not russian number
    return "+" + inputNumbersValue.substring(0, 16);
  }
};

const onKeyDown = function (e, input, setValue) {
  if (e.keyCode === 8 && getInputNumbersValue(input).length === 1) {
    setValue("");
  }
};

const onEmailInput = function (e, setValue, setError) {
  const re = /\S+@\S+\.\S+/,
    value = e.target.value;
  setValue(value);
  if (re.test(value) === false) {
    setError("Введите корректный email");
  } else {
    setError("");
  }
};

function onPhoneInput(e, setValue) {
  const input = e.target;
  let inputNumbersValue = getInputNumbersValue(input);
  const selectionStart = input.selectionStart;
  //   console.log("====================================");
  //   console.log("input", input);
  //   console.log("inputNumbersValue", inputNumbersValue);
  //   console.log("selectionStart", selectionStart);
  //   console.log("====================================");
  if (!inputNumbersValue) return (input.value = "");

  if (input.value.length !== selectionStart) {
    setValue(getFormattedPhoneInputValue(inputNumbersValue));
    input.selectionStart = selectionStart;
    input.selectionEnd = selectionStart;
  }

  input.addEventListener("keydown", () => onKeyDown(e, input, setValue));
  setValue(getFormattedPhoneInputValue(inputNumbersValue));
}

export { onPhoneInput, onEmailInput };
