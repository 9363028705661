import React, { useEffect, useState } from "react";

const Modal = (props) => {
  const [active, setActive] = useState(false);
  const Domain = "https://xanalytics.ru";

  const closeModal = (e) => {
    e.preventDefault();
    props.openModal(false);
    document.body.classList.remove("hidden");
  };

  // console.log(props.item);
  return (
    <>
      {/* <p onClick={() => props.openModal(false)}>1234567890</p> */}
      <div className={"modal active"} onClick={closeModal}>
        <div className={`modal__content`} onClick={(e) => e.stopPropagation()}>
          <div className="modal-header">
            <p className="modal-more">Подробнее о проекте</p>
            <img
              className="modal-close"
              src="/img/close.svg"
              alt="закрыть"
              onClick={closeModal}
            />
          </div>
          <img
            className="modal-img"
            src={`${Domain}${props.item.image}`}
            alt=""
          />
          <p className="modal-title" dangerouslySetInnerHTML={{ __html: props.item.description }}></p>

          {/* <p className="modal-title">{props.item.description}</p> */}
          <span
            className="modal-text"
            dangerouslySetInnerHTML={{ __html: props.item.text }}
          />
        </div>
      </div>
    </>
  );
};

export default Modal;
