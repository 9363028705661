import "./App.scss";
import React, { useEffect, useState } from "react";
import FormItem from "./FormItem";
import Modal from "./Modal";
import { onPhoneInput } from "./functions";

const App = () => {
  // const [value, setValue] = useState("")

  const [data, setData] = useState({});
  const [fileName, setFileName] = useState("");
  const [btnActive, setBtnActive] = useState(false);
  const [openModal, setOpenModal] = useState(false);
  const [formSended, setFormSended] = useState(false);
  const [error, setError] = useState("");
  const [modalId, setModalId] = useState();

  const [partners, setPartners] = useState({});
  const [activity, setActivity] = useState({});
  const [projects, setProjects] = useState({});
  const [contacts, setContacts] = useState({});

  const Domain = "https://xanalytics.ru";

  const fieldHandler = (name, value) => {
    setValue(name, value);
  };

  const setValue = (name, value) => {
    // console.log(name, value);
    data[name] = value;
    value ?? delete data[name];
    if (value === "") delete data[name];
    setData({ ...data });
    // console.log(Object.keys(data));
    if (
      Object.keys(data).includes("fio") &&
      Object.keys(data).includes("phone") &&
      Object.keys(data).includes("email")
    ) {
      setBtnActive(true);
    } else {
      setBtnActive(false);
    }
  };

  const sendForm = () => {
    const formData = new FormData();
    for (const [key, item] of Object.entries(data)) {
      formData.append(key, item);
      // console.log('====================================');
      // console.log(key, item);
      // console.log('====================================');
    }

    fetch(`${Domain}/api/feedback/`, {
      method: "POST",
      headers: {},
      body: formData,
    })
      .then((response) => {
        if (!response.ok) {
          setError("Неверно заполнены поля");
          throw new Error("Ошибка. Неверно заполнены поля");
        } else {
          setFormSended(true);
        }
        return response.json();
      })
      .catch((error) => {
        console.log("error", error.message);
      });
  };

  useEffect(() => {
    getItems("activity");
    getItems("partners");
    getItems("projects");
    getItems("contacts");
  }, []);

  const getItems = (page) => {
    fetch(`${Domain}/api/${page}/`, {
      method: "GET",
      headers: {},
    })
      .then((response) => {
        if (!response.ok) {
          // throw new Error(response.statusText)
        }
        return response.json();
      })
      .then((data) => {
        if (page === "partners") {
          setPartners(data);
        } else if (page === "activity") {
          setActivity(data);
        } else if (page === "projects") {
          setProjects(data);
        } else if (page === "contacts") {
          setContacts(data);
        }

        // console.log('====================================');
        // console.log('data', data);
        // console.log('====================================');
      })
      .catch((error) => console.log(error));
  };

  const setModal = (id) => {
    setOpenModal(true);
    setModalId(id);
    document.body.classList.add("hidden");
  };

  return (
    <div className={openModal ? "wrapper" : ""}>
      <div className="main-screen">
        <img className="bg-img" src="/img/main-bg.png" alt="" />
        <div className="container-center">
          <div className="header">
            <img
              className="header-logo"
              src="/img/logo.svg"
              alt="Хронос Аналитика"
            />
            <span className="header-line"></span>
            <div className="menu">
              <a href="#about">О нас</a>
              <a href="#projects">Проекты</a>
              <a href="#form">Оставить заявку</a>
            </div>
          </div>
          <h1>
            {/* Внедрение <b>инновационных</b> разработок в народное хозяйство */}
            Разработка и внедрение <b>инновационных</b> решений
          </h1>

          <div className="partners">
            <p className="partners-title">Партнеры</p>
            <div className="partners-carrousel">
              {Object.values(partners).map((item, idx) => {
                return (
                  <img
                    key={idx}
                    src={`${Domain}${item.image}`}
                    alt={item.title}
                    className="partners-logo"
                  />
                );
              })}
            </div>
          </div>
        </div>
      </div>

      <div id="projects" className="projects-block">
        <div className="container-center">
          <h2>Проекты</h2>
          <div className="projects-grid">
            {Object.values(projects)
              .reverse()
              .map((item, idx) => (
                <div
                  onClick={() => {
                    setModal(item.id);
                  }}
                  key={idx}
                  className="project-card"
                >
                  <span className="project-line"></span>
                  
                  <p className="project-text" dangerouslySetInnerHTML={{ __html: item.description }}></p>
                  {/* <img className="flake" src="/img/flake.svg" alt="" /> */}
                  <p className="project-more">Подробнее...</p>
                  <img
                    className="project-img"
                    src={`${Domain}${item.image}`}
                    alt=""
                  />
                </div>
              ))}
          </div>
        </div>
        <img className="frame1" src="/img/Frame1.png" alt="" />
      </div>

      {
        openModal && (
          <Modal
            id={modalId}
            openModal={setOpenModal}
            item={
              Object.values(projects).filter((proj) => proj.id === modalId)[0]
            }
          ></Modal>
        )

        // {(addButton && canAdd) && <Modal title="Создание контрагента" button={{ title: 'Новый контрагент', classes: 'add-absolute add-btn btn' }}><CounterpartyFastSearch addOption={addOption} openModal={setAddButton} /></Modal>}
      }

      <div id="about" className="activities-block">
        <div className="container-center">
          <h2>Направления деятельности</h2>
          <div className="activities-grid">
            {Object.values(activity)
              .reverse()
              .map((item, idx) => {
                return (
                  <div key={idx} className="activity-item">
                    <div className="mb-16 d-flex align-items-center">
                      <img
                        src={`${Domain}${item.image}`}
                        alt={item.title}
                        className="activity-icon"
                      />
                      <span className="activity-line"></span>
                    </div>
                    <p className="activity-text">{item.description}</p>
                  </div>
                );
              })}
          </div>
        </div>
      </div>
      <div className="footer">
        <div className="container-center">
          <div className="footer-wrap">
            <div className="contacts-info">
              <a className="email" href="mailto:info@xanalytics.ru">
              info@xanalytics.ru
              </a>
              <img
                className="header-logo mb-24"
                src="/img/logo.svg"
                alt="Хронос Аналитика"
              />
              {/* <p className='contacts-text'> </p> */}
              {contacts && (
                <span
                  className="contacts-text"
                  dangerouslySetInnerHTML={{ __html: contacts[0]?.text }}
                />
              )}
              <p className="site-inf">2024 Хронос Аналитика</p>
            </div>
            {/* <hr /> */}

            {!formSended ? (
              <div id="form" className="footer-form">
                <p className="footer-title">Заявка на вакансию</p>
                <span className="form-error">{error}</span>
                <form action="" method="POST">
                  <div className="form-container">
                    <FormItem
                      type={"text"}
                      name={"fio"}
                      classes={"_full"}
                      value={data.fio}
                      fieldHandler={fieldHandler}
                      placeholder={"ФИО"}
                      label={"Фамилия Имя Отчество"}
                    />
                    <FormItem
                      type={"phone"}
                      name={"phone"}
                      value={data.phone}
                      fieldHandler={fieldHandler}
                      placeholder={"+7"}
                      label={"Телефон"}
                      mask={onPhoneInput}
                      setValue={setValue}
                    />
                    <FormItem
                      type={"email"}
                      name={"email"}
                      value={data.email}
                      fieldHandler={fieldHandler}
                      placeholder={""}
                      label={"Электронная почта"}
                    />
                    <div className="d-flex _full">
                      <input
                        className="d-none"
                        id="file-btn"
                        onChange={(e) => {
                          setValue("file", e.target.files[0]);
                          setFileName(e.target.files[0].name);
                        }}
                        type="file"
                        name="file"
                      />
                      <label className={`add-file__btn`} htmlFor="file-btn">
                        {"Добавить файл"}
                      </label>
                      {fileName.length > 0 && (
                        <span className="file-name">{fileName}</span>
                      )}
                    </div>
                  </div>
                  {/* <input type="submit" id="save_button" className="d-none" value="Send" /> */}
                  <label
                    onClick={(e) => sendForm()}
                    htmlFor="save_button"
                    className={`${btnActive ? "" : "disabled"} save_button`}
                  >
                    Отправить
                  </label>
                  <p className="form-subtitle">
                    Нажимая на кнопку «Отправить», вы соглашаетесь с правилами
                    обработки персональных данных
                  </p>
                </form>
              </div>
            ) : (
              <div className="form-success">
                <p className="form-sended_title">Заявка отправлена </p>
                <p className="form-sended_subtitle">
                  Мы рассмотрим ваше резюме и&nbsp;обязательно с&nbsp;вами
                  свяжемся
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default App;
